import { CURRENT_TIME } from '../../../config/timeline-props'
import ItemCache from '../../../utils/ItemCache'
import { parseLayoutItem } from './items-helper'

/**
 * This refactored function was previously the "first inner loop" of TimelineItems.jsx
 */
export const findItemsWithinRange = (
  {
    items,
    startTime,
    endTime,
    startMargin,
    endMargin,
    offset,
    scale,
    layoutId,
    length,
    activeClassName,
    activeId,
    lang,
    cursorPos,
  },
  currentArrangement,
  itemCount,
  layoutItem,
  pxpt,
  trackInfo,
  tplId,
  vertical,
  _sizeScale,
  fadeLength,
  colSize,
  trackGroupSize,
  maxTracks,
  tpl
) => {
  const overflow = 10
  const groupingSize = 3
  const st = startTime - startMargin * pxpt
  const et = endTime + endMargin * pxpt
  const current = new Date().getFullYear() + 1
  const timeOffset = layoutItem?.timeOffset || 0
  const className = `${layoutItem?.className || ''} ${currentArrangement.className || ''}`
  const { timeSpacing, size, spacing, trackSide, rulerOffset } = parseLayoutItem(layoutItem, _sizeScale)

  let currentArrangementItem,
    trackCount = 0,
    idx = 0,
    limitZoom = true,
    outsideFirst = 1e15,
    outsideLast = -1e15

  // ###############  Start first inner loop to find items within range ###############
  while ((currentArrangementItem = currentArrangement.items?.[idx])) {
    idx++
    let { id } = currentArrangementItem
    const currentItem = items[id]
    if (!currentItem) {
      console.warn('no item id:', id)
      continue
    }
    id = currentItem.id || id

    // ***** step 1: determine start/end times and item type
    const ot = timeOffset * pxpt
    const start = currentItem._start

    let end = currentItem._end || CURRENT_TIME
    if (end === CURRENT_TIME) {
      end = current
    }

    let display = !currentItem._display || currentItem._display === 'range' ? 'range' : 'point'
    if (!end) {
      display = 'point'
      end = start + 20 * pxpt
    }

    const track = currentArrangementItem.track
    if (!trackInfo[tplId][track]) {
      trackInfo[tplId][track] = {
        idxOnCursor:     -1,
        idxBeforeCursor: -1,
        idxAfterCursor:  -1,
        pointItems:      [],
        rangeItems:      [],
      }
    }
    trackInfo[tplId].minTrack = Math.min(trackInfo[tplId].minTrack, track)
    trackInfo[tplId].maxTrack = Math.max(trackInfo[tplId].maxTrack, track)
    const itemTrack = trackInfo[tplId][track]

    // ***** step 2: check item is in range and not too small
    let l = 0,
      pointHeight = 0
    if (display === 'point') {
      // point items
      pointHeight = ItemCache.get(id) || 0
      l = vertical ? pointHeight : size

      if (l) {
        l += timeSpacing
        end = start + l * pxpt

        // we only bail early for point item types if we already have a size calcuated
        if (start > et - ot) {
          // beyond the end of the range
          continue
        } else if (end < st - ot && itemTrack.pointItems.length === 0) {
          // before the start of the range but only skip if other items not already added
          // (an earlier item might have a greater length that expands into the visible range)
          continue
        }
      }
    } else {
      // range items
      // skip if out of range or too small
      if (start > et) {
        outsideFirst = Math.min(start, outsideFirst)
        outsideLast = Math.max(end, outsideLast)
      }

      if (end < st) {
        outsideFirst = Math.min(start, outsideFirst)
        outsideLast = Math.max(end, outsideLast)
      }

      l = Math.min((end - start) / pxpt, length + overflow + overflow)
      if (start > et || end < st || l < 2) {
        continue
      }
    }

    // ***** step 3: work out positioning and cursor intersection for the item
    let p1,
      p2,
      a = 1
      
    if (start <= st && end >= et) {
      // item off the start and end of the visible range
      p1 = Math.max((start - st) / pxpt, -overflow) - startMargin
      p2 = Math.min((end - st) / pxpt, length + overflow) + endMargin
      limitZoom = limitZoom && true
    } else if (start <= st) {
      // item starts before the range but ends within it
      p2 = (end - startTime) / pxpt
      p1 = display === 'range' ? p2 - l : (start - startTime) / pxpt

      const fl = Math.min(l, fadeLength)
      if (fl > 0) {
        a = (p2 + startMargin) / fl
      }

      a = Math.min(Math.max(a, 0), 1).toFixed(3) * 1
      limitZoom = false
    } else if (end >= et) {
      // item starts within range but ends outside of it
      p1 = (start - startTime) / pxpt
      p2 = p1 + l

      const fl = Math.min(l, fadeLength)
      if (fl > 0) {
        a = (length - p1 - startMargin) / fl
      }

      a = Math.min(Math.max(a, 0), 1).toFixed(3) * 1
      limitZoom = false
    } else {
      // starts and ends within the range
      p1 = (start - startTime) / pxpt
      p2 = (end - startTime) / pxpt
      limitZoom = false

      if (display === 'range') {
        l = p2 - p1
      }
    }

    l = Math.max(Math.ceil(l), 0)
    if (l <= 2 && display === 'range') {
      // too small a range to show
      continue
    }

    const active = id === activeId
    const zIndex = idx + (active && display !== 'point' ? 200000 : 100000)

    // ***** step 4: create the timeline item
    const tracks = [0]
    let pos = display === 'point' ? tracks[track] : 0

    if (!pos || !isFinite(pos)) {
      pos = tracks[track] = track * colSize
    }
    pos += rulerOffset

    const t = startMargin + p1
    const timePos = t + offset

    if (!isFinite(tracks[track + 1])) {
      tracks[track + 1] = pos + colSize
    }
    trackCount = Math.max(trackCount, track)

    const props = {
      lang:            lang,
      key:             `${layoutId}-${id}`,
      item:            currentItem,
      startTime:       start,
      endTime:         end,
      timePos:         timePos,
      rulerPos:        timePos, // used by the point event markers as the original position on the ruler
      itemPos:         (idx - 1) / itemCount,
      track:           track,
      trackSpacing:    spacing,
      groupSize:       Math.floor(trackGroupSize),
      margin:          Math.floor(rulerOffset),
      trackPos:        Math.floor(pos),
      maxTracks:       maxTracks,
      side:            trackSide,
      alpha:           a,
      zIndex:          zIndex,
      length:          l,
      pointHeight:     pointHeight,
      size:            size,
      type:            display,
      timeOffset:      timeOffset,
      itemOffset:      0,
      groupLength:     l,
      scale:           scale,
      styleKey:        `${layoutId}-${tplId}`,
      template:        tpl,
      focus:           active,
      className:       className,
      activeClassName: active ? activeClassName : '',
    }

    if (display === 'range') {
      // range items
      props.timePos = Math.floor(props.timePos)
      itemTrack.rangeItems.push(props)
    } else {
      // point items
      // console.log('create point item:', id);
      // check for co-incident start date entries
      const prev = itemTrack.pointItems[itemTrack.pointItems.length - 1]
      let grouped = false

      if (prev) {
        // NOTE: originally this was < 10 to enable grouping
        if (props.timePos - prev.timePos < groupingSize) {
          // add current to the prev group
          if (!prev.group) {
            prev.group = []
          }
          props.itemOffset = prev.groupLength
          prev.groupLength += l
          prev.group.push(props)
          grouped = true
        } else {
          prev.nextItem = props
        }
      }

      if (!grouped) {
        itemTrack.pointItems.push(props)
      }

      const itm = grouped ? prev : props
      // find item position relative to cursor
      if (itm.timePos <= cursorPos && cursorPos <= itm.timePos + itm.groupLength) {
        // select the last item index overlapping the cursor
        itemTrack.idxOnCursor = itemTrack.pointItems.length - 1
        itemTrack.idxBeforeCursor = itemTrack.idxOnCursor - 1
      } else if (itm.timePos + itm.groupLength < cursorPos) {
        // ends before cursor
        itemTrack.idxBeforeCursor = itemTrack.pointItems.length - 1
        itemTrack.idxOnCursor = -1 // incase a previous item was taller than the current
      } else if (itm.timePos > cursorPos && itemTrack.idxAfterCursor === -1) {
        // starts after cursor
        itemTrack.idxAfterCursor = itemTrack.pointItems.length - 1
      }
    }
  }
  // ###############  End first inner loop to find items within range ###############

  return {
    _limitZoom: limitZoom,
    outsideFirst,
    outsideLast,
    trackCount,
  }
}
